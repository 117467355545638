import checkIcon from '../../../../static/img/Depressiya/check-icon.svg'
import styled from 'styled-components'
import { Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 32px;
  }

  @media (max-width: ${size.md}) {
    gap: 24px;
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const CheckImage = styled.img.attrs(() => ({
  src: checkIcon
}))``

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const InfoContainer = styled.div`
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 700px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: ${size.xs}) {
    flex-direction: column;
    align-items: flex-start;
  }

  ${Text.Large} {
    color: #fff;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
