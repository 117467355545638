import styled from 'styled-components'
import { Button, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const Card = styled.div`
  max-width: 588px;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};
  max-width: 588px;
  height: 100%;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ${CardTitle} {
    margin-bottom: 24px;
  }
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`

export const InfoContainer = styled.div`
  height: 426px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${size.lg}) {
    height: 478px;
  }

  @media (max-width: ${size.md}) {
    height: auto;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }
`
