import cardEightIcon from '../../../../static/img/Depressiya/card-eight.svg'
import cardFiveIcon from '../../../../static/img/Depressiya/card-five.svg'
import cardFourIcon from '../../../../static/img/Depressiya/card-four.svg'
import cardOneIcon from '../../../../static/img/Depressiya/card-one.svg'
import cardSevenIcon from '../../../../static/img/Depressiya/card-seven.svg'
import cardSixIcon from '../../../../static/img/Depressiya/card-six.svg'
import cardThreeIcon from '../../../../static/img/Depressiya/card-three.svg'
import cardTwoIcon from '../../../../static/img/Depressiya/card-two.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const TitleSection = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;
  color: #fff;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
  }
`

export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 40px;

  @media (max-width: ${size.md}) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 32px;
  padding: 24px;

  @media (max-width: ${size.lg}) {
    height: 653px;
  }

  @media (max-width: ${size.md}) {
    height: auto;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const TextWrapper = styled.div``

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``

export const CardSixImage = styled.img.attrs(() => ({
  src: cardSixIcon
}))``

export const CardSevenImage = styled.img.attrs(() => ({
  src: cardSevenIcon
}))``

export const CardEightImage = styled.img.attrs(() => ({
  src: cardEightIcon
}))``

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`
