import React from 'react'
import {
  CheckImage,
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  Wrapper
} from './HowToChooseRight.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToChooseRight = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H2>
                Как правильно подобрать специалиста по лечению депрессии?
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Выбор подходящего психолога для консультации при депрессии — это
                ключевой шаг на пути к выздоровлению. Вот некоторые факторы,
                которые стоит учесть при выборе психолога или психотерапевта:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <CheckImage />
              <Text.Large semiBold>
                Профессиональная квалификация: образование, соответствующие
                обучения и регулярные супервизии станут первым маячком хорошего
                специалиста.
              </Text.Large>
            </ListItem>
            <ListItem>
              <CheckImage />
              <Text.Large semiBold>
                Опыт и специализация: психолог должен имеет опыт работы с
                депрессией и соответствующую специализацию.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
