import React from 'react'
import {
  CardContainer,
  CloudsImage,
  Content,
  TextWrapper,
  TitleSection,
  Wrapper
} from './SignsOfDepression.styles'

export const SignsOfDepression = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <CloudsImage />
          <TitleSection>Признаки депрессивного состояния</TitleSection>
          <TextWrapper>
            Депрессия — это расстройство настроения или, используя термины,
            аффективное психическое расстройство. Депрессия — это не просто
            состояние, когда человек грустит или не хочет что-то делать. Это
            болезнь с разнообразными симптомами, которые исследуются на приеме у
            психиатра.
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
