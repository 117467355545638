import React from 'react'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Card,
  CardsContainer,
  Content,
  ImageWrapper,
  TextWrapper,
  TitleCard,
  TitleSection,
  Wrapper
} from './CardsBlock.styles'
import { Event as GAEvent } from '../../../components/GA'
import { cardData } from './cardData'

export const CardsBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleSection>
            Вы можете заподозрить у себя депрессию, если замечаете:
          </TitleSection>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <TitleCard>{card.title}</TitleCard>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>

          <ButtonsWrapper>
            <Button.NewPrimary
              href="/wizard/"
              onClick={GAEvent.openSemeinyiPageFirstScreen}
              size="large"
              type="link"
            >
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonsWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
