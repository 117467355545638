import stepOneIcon from '../../../../static/img/Depressiya/step-one.svg'
import stepThreeIcon from '../../../../static/img/Depressiya/step-three.svg'
import stepTwoIcon from '../../../../static/img/Depressiya/step-two.svg'
import styled from 'styled-components'
import { Montserrat, size } from '../../../constants'
import { Title } from '../../../../youtalk-storybook/src/ui'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const CardTitle = styled.p`
  font-style: normal;
  font-family: ${Montserrat};
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
    text-align: center;

    @media (max-width: ${size.md}) {
      text-align: left;
    }
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 564px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: white;
  border-radius: 32px;

  @media (max-width: ${size.lg}) {
    height: 720px;
  }

  @media (max-width: ${size.md}) {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: auto;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }
`

export const StepOneImage = styled.img.attrs(() => ({
  src: stepOneIcon
}))``

export const StepTwoImage = styled.img.attrs(() => ({
  src: stepTwoIcon
}))``

export const StepThreeImage = styled.img.attrs(() => ({
  src: stepThreeIcon
}))``

export const TextWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
