import React from 'react'
import {
  Card,
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardThreeImage,
  CardTwoImage,
  CardsContainer,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './HowToUnderstand.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

const cardData = [
  {
    image: <CardOneImage />,
    text: 'Отсутствие интереса к тем занятиям, которые раньше приносили удовольствие'
  },
  {
    image: <CardTwoImage />,
    text: 'Сложности с выполнением повседневных обязанностей или поддержанием гигиены'
  },
  {
    image: <CardThreeImage />,
    text: 'Ухудшение отношений с близкими из-за частых перемен настроения'
  },
  {
    image: <CardFourImage />,
    text: 'Физические симптомы, такие как боли, головные боли, проблемы с пищеварением'
  },
  {
    image: <CardFiveImage />,
    text: 'Суицидальные мысли или намерения'
  }
]

export const HowToUnderstand = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Как понять, что уже пора
                <br />
                на консультацию к терапевту?
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Некоторые симптомы появляются и усиливаются в моменты, когда
                депрессия переходит в среднюю или тяжелую. В такой момент
                критически важно не надеяться на самолечение, а обратиться к
                профессионалам.
              </Text.Large>
              <Text.Large semiBold>
                Если вы замечаете, что следующие симптомы становятся более
                интенсивными и длительными, это может быть сигналом о
                необходимости обратиться за помощью:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <Text.Large semiBold>{card.text}</Text.Large>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
