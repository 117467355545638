import React from 'react'
import {
  CardEightImage,
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardSevenImage,
  CardSixImage,
  CardThreeImage,
  CardTwoImage
} from './CardsBlock.styles'

export const cardData = [
  {
    title: 'Долгий период «плохого настроения»',
    text: 'Вы можете заметить, что ваше настроение стабильно больше пары недель держится на планке «хуже обычного» или «совсем грустно и тоскливо». Оно может немного подниматься от хорошего фильма или встречи с друзьями, но не остается таким.',
    image: <CardOneImage />
  },
  {
    title: 'Постоянная усталость и снижение энергии',
    text: 'Вы чувствуете себя постоянно уставшими и неспособными справиться с обыденными задачами, которые ранее не вызывали трудностей.',
    image: <CardTwoImage />
  },
  {
    title: 'Изменения в аппетите',
    text: 'У вас могут возникнуть проблемы с аппетитом, либо наоборот, появится желание «заедать» стресс пищей.',
    image: <CardThreeImage />
  },
  {
    title: 'Сон и бодрствование',
    text: 'Депрессия может повлиять на сон, вызывая бессонницу или, наоборот, чрезмерную сонливость.',
    image: <CardFourImage />
  },
  {
    title: 'Чувство отчуждения',
    text: 'Вам может показаться, что вы чужой в этом мире, что вас окружают лишь негативные эмоции.',
    image: <CardFiveImage />
  },
  {
    title: 'Утрата интереса',
    text: 'Ранее любимые занятия и хобби перестают приносить радость и интерес.',
    image: <CardSixImage />
  },
  {
    title: 'Снижение самооценки',
    text: 'Вы начинаете относиться к себе с негодованием, уверенность в себе уменьшается.',
    image: <CardSevenImage />
  },
  {
    title: 'В таком случае — обратитесь к специалисту',
    text: 'Консультация психолога при депрессии может поддержать ваши опасения и помочь справиться с этим состоянием, но поставить диагноз и прописать лекарства может только врач-психиатр.',
    image: <CardEightImage />
  }
]
