import React from 'react'
import {
  BlockContainer,
  Br,
  ButtonsWrapper,
  Content,
  H1asH2styles,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TextH1styles,
  Wrapper
} from './HeroBlockDepressiya.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlockDepressiya = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextH1styles>
                  Онлайн <span>•</span> консультации
                  <br /> с психологом
                </TextH1styles>
              </TitleWrapper>
              <DescriptionWrapper color="#fff">
                <Text.Large semiBold>
                  Заполните анкету — мы подберем специалистов,
                  <Br /> которые умеют работать с депрессией
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <HeroImage alt="Записаться к онлайн терапевту для лечения депрессии" />
            </ImageWrapper>
          </BlockContainer>
          <InfoContainer>
            <TitleWrapper>
              <H1asH2styles>
                <a href="https://youtalk.ru/wizard/">Записаться</a> к онлайн
                терапевту для лечения депрессии
              </H1asH2styles>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Депрессия — это сложное и серьезное психическое расстройство,
                влияющее на настроение, физическое состояние организма,
                способность радоваться жизни и находить смысл в повседневных
                вещах. Чтобы полностью избавиться от депрессии часто нужна
                помощь специалистов. Подобрать профессионального психолога для
                работы с депрессией вы можете в YouTalk.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
