import React from 'react'
import loveIcon from '../../../../static/img/Pshiholog-v-chate/love-icon.svg'
import messageIcon from '../../../../static/img/Pshiholog-v-chate/message-icon.svg'
import studyIcon from '../../../../static/img/Pshiholog-v-chate/study-icon.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './AdvantagesYouTalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const AdvantagesYouTalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>
                Преимущества терапии депрессивных состояний в YouTalk
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Депрессия часто не оставляет сил даже на самую базовую заботу о
                себе. YouTalk позволяет не тратить силы ещё и на долгий поиск
                профессионального и этичного психолога. Сервис уже провел
                строгий отбор и разработал алгоритм для точного подбора
                подходящего специалиста
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Все наши психологи по работе с депрессией — опытные
                профессионалы, обладающие профильным образованием и стажем
                работы от года. В нашу команду проходит только 4%
                психотерапевтов, справившихся с отбором.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={loveIcon} />
              <Text.Large semiBold>
                В YouTalk есть возможность назначить консультацию на удобное для
                вас время, так как наши психологи работают в разных часовых
                поясах.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Консультации в YouTalk проводятся анонимно. Нам необходимы
                только ваши контактные данные для связи с психотерапевтом и
                администратором. Если захотите, вы можете сохранить всю личную
                информацию в тайне.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
