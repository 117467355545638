import React from 'react'
import {
  BlockImage,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourSpecialist.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { TitleWrapper } from '../Common.styles'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите психолога и запишитесь на консультацию прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Совладание с депрессией — это путь, который можно пройти вместе
                с опытными специалистами. Примите решение и начните ваш путь к
                выздоровлению уже сегодня. Вы заслуживаете счастливую и здоровую
                жизнь. А YouTalk сопроводит вас на пути к выздоровлению от
                депрессии.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите психолога и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
