import heroIcon from '../../../../static/img/Depressiya/heroBlock-image.svg'
import heroIcon1024 from '../../../../static/img/Depressiya/heroBlock-image-1024.svg'
import heroIcon320 from '../../../../static/img/Depressiya/heroBlock-image-320.svg'
import heroIcon414 from '../../../../static/img/Depressiya/heroBlock-image-414.svg'
import heroIcon768 from '../../../../static/img/Depressiya/heroBlock-image-768.svg'
import styled from 'styled-components'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const InfoContainer = styled.div`
  width: 100%;

  &:last-child {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media (max-width: ${size.lg}) {
      flex-direction: column;
      gap: 16px;
    }
  }
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  margin-bottom: 72px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 32px;
  }

  ${InfoContainer} {
    ${Text.Large} {
      @media (max-width: ${size.sm}) {
        max-width: 292px;
      }

      @media (max-width: ${size.xs}) {
        max-width: 220px;
      }
    }
  }
`

export const Br = styled.br`
  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const TextH1styles = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #fff;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }

  & > span {
    color: #35b3a9;
  }
`

export const H1asH2styles = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #fff;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }

  & > a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #fff;
    }
  }
`

export const HeroImage = styled.img.attrs(() => ({}))`
  content: url(${heroIcon});
  width: 588px;
  height: 588px;

  @media (max-width: ${size.lg}) {
    content: url(${heroIcon1024});
    width: 100%;
    height: 454px;
  }

  @media (max-width: ${size.md}) {
    content: url(${heroIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${heroIcon414});
    height: 227px;
  }

  @media (max-width: ${size.xs}) {
    content: url(${heroIcon320});
    height: 288px;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`
