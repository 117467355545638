import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Card,
  CardTitle,
  Content,
  DescriptionWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './CanYouHealDepression.styles'
import { Event as GAEvent } from '../../../components/GA'

export const CanYouHealDepression = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>Можно ли вылечить депрессию самостоятельно?</Title.H2>
          </TitleWrapper>
          <InfoContainer>
            <Card>
              <CardTitle>
                Попыток самостоятельно справиться с депрессией часто
                недостаточно из-за тяжести состояния
              </CardTitle>
              <Text.Large>
                Лечение обычно требует индивидуального и комплексного подхода.
                Конечно, поддержка от близких и друзей важна, но помощь
                профессионального психолога при депрессии или психотерапевта
                может предоставить более эффективные инструменты и стратегии для
                лечения.
              </Text.Large>
            </Card>

            <DescriptionWrapper>
              <Text.Large semiBold>
                Если вы столкнулись с депрессией средней или высокой степени, мы
                не рекомендуем рисковать и пробовать лечить себя самостоятельно.
                Использование методов без консультации специалиста может
                ухудшить состояние. Лучше обратиться хотя бы за одной-двумя
                консультациями. Психолог сможет оценить ситуацию, разработать
                план действий, предоставит техники, которые помогут, и, при
                необходимости, направит к психиатру для назначения
                медикаментозной терапии.
              </Text.Large>
              <CardTitle>
                Если возможности обратиться к специалисту нет, то можно начать с
                простых упражнений.
              </CardTitle>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="https://drive.google.com/file/d/1gyc63TCphnNGM7LRv-CSwD_Eu4pibTFZ/view?usp=share_link"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  target="_blank"
                  type="link"
                >
                  Получить памятку
                </Button.NewPrimary>
              </ButtonsWrapper>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
