import React from 'react'
import {
  CardContainer,
  CardTitle,
  Container,
  Content,
  ImageWrapper,
  StepOneImage,
  StepThreeImage,
  StepTwoImage,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HowSessionIsGoing.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const HowSessionIsGoing = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper color="white">
            <Title.H2>
              Как проходят онлайн-сессии с психотерапевтом в YouTalk?
            </Title.H2>
          </TitleWrapper>
          <CardContainer>
            <Container>
              <ImageWrapper>
                <StepOneImage />
              </ImageWrapper>
              <TextWrapper>
                <CardTitle>
                  Все консультации в YouTalk с психологами проходят онлайн
                </CardTitle>
                <Text.Large semiBold>
                  Через Zoom, Скайп, Google Meet или другие сервисы видеосвязи.
                </Text.Large>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <StepTwoImage />
              </ImageWrapper>
              <TextWrapper>
                <CardTitle>
                  Психолог свяжется с вами в WhatsApp или Telegram
                </CardTitle>
                <Text.Large semiBold>
                  Некоторые специалисты могут организовать консультацию в тот же
                  день, но часто консультация назначается на один из ближайших
                  дней или на неделю вперед. Специалист расскажет о том, какой
                  сервис будет использован, как к нему подключиться, и ответит
                  на вопросы.
                </Text.Large>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <StepThreeImage />
              </ImageWrapper>
              <TextWrapper>
                <CardTitle>
                  Во время первой сессии психолог соберет анамнез
                </CardTitle>
                <Text.Large semiBold>
                  Выяснит, что вас беспокоит, как эти проблемы проявляются, как
                  развивались и когда появились в первый раз. Он задаст
                  дополнительные уточняющие вопросы, поможет вам сформулировать
                  запрос на терапию и расскажет о том, как будут проходить
                  сеансы в выбранном подходе. Некоторые специалисты дают
                  домашние задания, другие ограничиваются только работой на
                  консультациях.
                </Text.Large>
              </TextWrapper>
            </Container>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
